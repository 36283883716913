import React, { Component } from 'react'
import reactLogo from '../../assets/images/react_logo.png'
import jsLogo from '../../assets/images/js_logo.png'
import pythonLogo from '../../assets/images/python_logo.jpg'
import htmlLogo from '../../assets/images/html_logo.png'
import gitLogo from '../../assets/images/gitLogo.png'
import GitRepo from './GitRepo'

const courses = [
  {
    name: '10 Days Of Command Lines, Git and GitHub',
    topics: ['Command Lines, Git and GitHub', 'GitHub'],
    days: 10,
    status: 'ongoing',
    src: 'gitLogo',
    image: gitLogo,
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/10-days-of-git-and-github',
    stars:
      'https://img.shields.io/github/stars/asabeneh/10-days-of-git-and-github?style=flat',
    description:
      'A step by step guide to learn command lines, Git and GitHub. ',
  },
  {
    name: '30 Days Of HTML',
    topics: ['HTML', 'HTML Elements'],
    days: 30,
    status: 'ongoing',
    src: 'htmlLogo',
    image: htmlLogo,
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/30-Days-Of-HTML',
    stars:
      'https://img.shields.io/github/stars/asabeneh/30-Days-Of-HTML?style=flat',
    description:
      'A step by step guide to learn the concept of HTML, DOM tree, and web development in 30 days. ',
  },
  {
    name: '30 Days Of JavaScript',
    topics: ['JavaScript', 'ES6', 'Promise', 'async and await', 'DOM'],
    days: 30,
    status: 'done',
    src: 'jsLogo',
    image: jsLogo,
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/30-Days-Of-JavaScript',
    stars:
      'https://img.shields.io/github/stars/asabeneh/30-Days-Of-JavaScript?style=flat',
    description:
      '30 days of JavaScript programming challenge is a sep by step guide to learn JavaScript programming language in 30 days',
  },
  {
    name: '30 Days Of React',
    topics: ['React', 'JSX', 'Component', 'Props vs State', 'Virtual DOM'],
    days: 30,
    status: 'Done',
    src: 'reactLogo',
    image: reactLogo,
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/30-Days-Of-React',
    stars:
      'https://img.shields.io/github/stars/asabeneh/30-Days-Of-React?style=flat',
    description:
      '30 Days of React challenge is a step by step guide to learn React in 30 days. This challenge needs an intermediate level of HTML, CSS, and JavaScript knowledge. It is recommended to feel good at JavaScript before you start to React.',
  },
  {
    name: '30 Days Of Python',
    topics: [
      'Python',
      'Flask',
      'Numpy',
      'Pandas',
      'Statistics',
      'API',
      'MongoDB',
    ],
    days: 30,
    status: 'Done',
    questions: 'Above 500',
    src: 'pythonLogo',
    image: pythonLogo,
    projects: 'Two',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/30-Days-Of-Python',
    stars:
      'https://img.shields.io/github/stars/asabeneh/30-Days-Of-Python?style=flat-square',
    description:
      '30 days of Python programming challenge is a sep by step guide to learn Python programming language in 30 days.',
  },

  {
    name: 'JavaScript For Everyone',
    topics: ['JavaScript', 'ES6', 'Promise', 'async and await', 'DOM'],
    days: 30,
    status: 'done',
    src: 'jsLogo',
    image: jsLogo,
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/JavaScript-for-Everyone',
    stars:
      'https://img.shields.io/github/stars/asabeneh/JavaScript-for-Everyone?style=flat',
    description:
      'A step by step guide to learn JavaScript and programming in general',
  },
  {
    name: 'React For Everyone',
    topics: ['React', 'JavaScript', 'ES6', 'Promise', 'async and await', 'DOM'],
    days: 30,
    src: 'reactLogo',
    image: reactLogo,
    status: 'Ongoing',
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/React-For-Everyone',
    stars:
      'https://img.shields.io/github/stars/asabeneh/React-For-Everyone?style=flat',
    description: 'A step by step guide to learn and master react.js',
  },
  {
    name: 'Python For Everyone',
    topics: ['Python', 'Numpy', 'Pandas'],
    days: 30,
    src: 'pythonLogo',
    image: pythonLogo,
    status: 'Ongoing',
    questions: 'Above 500',
    projects: 'About 30',
    interviewQns: '',
    githubUrl: 'https://github.com/Asabeneh/Python-for-Everyone',
    stars:
      'https://img.shields.io/github/stars/asabeneh/Python-for-Everyone?style=flat',
    description: '',
  },
]

const GitRepos = ({ techs }) => {
  const courseList = courses.map((course) => (
    <GitRepo key={course.name} course={course} />
  ))
  return (
    <section className='day'>
      <h1 className=''>Git Repositories</h1>
      <small>Popular GitHub Repositories by Asabeneh Yetayeh</small>
      <div className='courses'>{courseList}</div>
    </section>
  )
}

export default GitRepos
