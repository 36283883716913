import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../context/UserContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import "react-quill/dist/quill.core.css"
import hljs from "highlight.js"
import { Navigate, useParams } from 'react-router-dom'
import Editor from '../components/courses/Editor'
import { Col, Row } from 'reactstrap'

hljs.configure({
  // optionally configure hljs
  languages: ["javascript", "python", "c", "c++", "java", "HTML", "css", "matlab"],
});

const modules = {
  syntax: {
    highlight: function (text) {
      return hljs.highlightAuto(text).value;
    },
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: ['Roboto', 'Lora','Lato', 'Nunito', 'Montserrat', 'Poppins', 'Raleway'] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    [{ 'script': 'sub'}, { 'script': 'super' }], 
    ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]


const EditPost = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    category: '',
    summary: '',
    content: '',
    file: '',
    video:''
  }
  const [formData, setFormData] = useState(initialState)
  const [files, setFiles] = useState('')
  const [cover, setCover] = useState('')
  const [loading, setLoading] = useState(false)
  const {user, setUser } = useContext(UserContext)
  const [content, setContent] = useState('')
  const [redirect, setRedirect] = useState(false)
  const { id } = useParams()
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  useEffect(() => {
    fetchPost()
  }, [])
  const fetchPost = async () => {
    const url = `/api/posts/${id}`
    const response = await fetch(url)
    const data = await response.json()
    const { title, subtitle, category, summary, cover} = data
    setFormData({ title, subtitle, category, summary, content, file:cover })
    setContent(data.content)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('title', formData.title)
    data.append('subtitle', formData.subtitle)
    data.append('summary', formData.summary)
    data.append('content', content)
    data.append('category', formData.category)
    data.append('id', id)
    if (formData.file) {
      data.append('file', formData.file)
    }

    try {
      const url = `/api/posts/${id}`
      const response = await fetch(url, {
        method: 'PUT',
        body: data,
      })
      console.log('Response:', response)
      if (response.ok) {
        setRedirect(true)
      }
      console.log(response)
    } catch (error) {
      console.log('Post create error', error)
    }
  }
  if(!user) {
    return <Navigate to={'/login'} />
  }
  if (redirect) {
    return <Navigate to={`/posts/${id}`} />
  }
console.log(formData)
  return (
    <Row>
      <form onSubmit={onSubmit}>
        <input
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
        />
        <input
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='Subtitle'
        />
         <textarea
          type='text'
          name='summary'
          value={formData.summary}
          onChange={onChange}
          placeholder='Summary'

        />
        <input
          type='text'
          name='category'
          value={formData.category}
          onChange={onChange}
          placeholder='Category'
        />

<ReactQuill
          theme='snow'
          value={content}
          name='content'
          onChange={setContent} 
          modules={modules}
          className='editor-container'
        />
        {/* <textarea name="content" id="" cols="90" rows="10"></textarea> */}
        <input
          type='file'
          onChange={(e) =>
            setFormData({ ...formData, file: e.target.files[0] })
          }
          accept='image/*'
        />
         {
          formData && formData.file && 
          
          formData.file.url && <Col> <img
            src={formData.file.url}
            className='img-fluid'
            alt=""
          />
          </Col>
         }

         {
          formData && formData.file && formData.file.name && <Col>
          <img
            src={URL.createObjectURL(formData.file)}
            className='img-fluid'
            alt=""
          />
          </Col>
         }
        <button style={{ marginTop: '5px' }}>Update Post</button>
      </form>
    </Row>
  )
}

export default EditPost
