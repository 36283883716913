import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { UserContext } from '../../../context/UserContext'
import headerStyles from './Header.module.css'
import { NavLink as Link, useNavigate} from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import axios from 'axios'

const Header = (args) => {
  const { user, setUser } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  // const [collapsed, setCollapsed] = useState(true);
  // const toggleNavbar = () => setCollapsed(!collapsed);

  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  axios.defaults.withCredentials = true
  const logout = async () => {
    const url = '/api/users/logout'
    await fetch(url, { method: 'POST', credentials: 'include' })
    setUser(null)
    navigate('/login')
  }
/*   useEffect(() => {
    const url = '/api/users/profile'
    setLoading(true)
    fetch(url, { credentials: 'include' })
      .then((response) => response.json())
      .then((result) => {
        setUser(result.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
      })
  }, [setUser]) */
  return (
    <Navbar {...args} expand='md' className={headerStyles.navbar}>
      <NavbarBrand tag={Link} to='/' className='me-auto'>
        &#123;30DoR&#125;
      </NavbarBrand>
      <NavbarToggler onClick={toggle} className='me-2' />
      <Collapse isOpen={isOpen} navbar id='basic-navbar-nav'>
        <Nav className='ms-auto' navbar>
          <NavItem>
            <NavLink tag = {Link} to='/' activeclassname='active'>
               Course
              </NavLink>
            </NavItem>
          {/* <NavItem>
            <NavLink  tag = {Link} to='/challenges' exact activeclassname='active'>
                Challenges
              </NavLink>
            </NavItem> */}
          <NavItem>
            <NavLink tag={Link} to='/about' activeclassname='active'>
              About
            </NavLink>
          </NavItem>
          {user && (
            <NavItem>
              <NavLink tag={Link} to={`/profile/${user?.id}`}>
                Profile
              </NavLink>
            </NavItem>
          )}
           {user && user.role[0] === 'Admin' && (
            <NavItem>
              <NavLink tag={Link} to={`/dashboard`}>
                Dashboard
              </NavLink>
            </NavItem>
          )}

          {/* <NavItem>
            <NavLink
            tag = {Link}
                to='/dummy-data-generator'
                exact
                activeclassname='active'
              >
                Dummy Data
              </NavLink>
            </NavItem> */}
          {user && user.role[0] === 'Admin' && (
            <NavItem>
              <NavLink tag={Link} to='/create' activeclassname = 'active'>
                Add
              </NavLink>
            </NavItem>
          )}

          {!user && (
            <>
              <NavItem>
                <NavLink tag={Link} to='/login' activeclassname = 'active'>
                  Login
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to='/register' activeclassname = 'active'>
                  Register
                </NavLink>
              </NavItem>
            </>
          )}
          {user && (
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  src={user && user.avatar}
                  alt={user && user.firstName}
                  className='me-1'
                  title={user && user.firstName}
                  style={{ width: '10%', borderRadius: '50%' }}
                />
                <span>{user.firstName}</span>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem>
                  {' '}
                  <span tag={Link} onClick={logout}>
                    Logout
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  )
}

Header.propTypes = {}
export default Header
