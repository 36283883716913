import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import authStyles from './Auth.module.scss'
import axios from 'axios'
import TextInputField from '../../components/shared/TextInputField'

export const RegisterPage = () => {
  const initialState = {
    firstName: '',
    lastName:'',
    email: '',
    password: '',
    password2: '',
  }
  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [errors, setErrors] = useState({})
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    const { firstName, lastName, email, password } = formData
    try {
      setLoading(true)
      const url = '/api/users/register'
      const response = await axios.post(url, { firstName, lastName, email, password })
      console.log(response)
      setLoading(false)
      setRedirect(true)
    } catch (error) {
      setLoading(false)
      setErrors(error.response.data)
      console.log('REGISTRATON FAILED', error, error.response.data)
    }
  }

  if (redirect) {
    return <Navigate to = {'/login'} />
  }

  console.log(errors)


  return (
    <div className={`${authStyles.authWrapper} auth`}>
      <form
        onSubmit={onSubmit}
        className={authStyles.register}
      >
        <h1>Sign up</h1>
        <TextInputField
          type='text'
          name='firstName'
          value={formData.firstName}
          onChange={onChange}
          placeholder='First name'
          error = {errors.firstName}
        />
         <TextInputField
          type='text'
          name='lastName'
          value={formData.lastName}
          onChange={onChange}
          placeholder='Last name'
          error = {errors.lastName}
        />
        <TextInputField
          type='text'
          name='email'
          value={formData.email}
          onChange={onChange}
          placeholder='Email'
          error = {errors.email}
        />
        <TextInputField
          type='password'
          name='password'
          value={formData.password}
          onChange={onChange}
          placeholder='Password'
          error = {errors.password}
        />
        <button>{loading ? '...': 'Sign Up'}</button>
      </form>
    </div>
  )
}

export default RegisterPage
