import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Button,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { useParams, useNavigate } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
// import {IEmployee} from '../../models/employee.model'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.core.css'


const modules = {
  syntax: {
    highlight: true
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [
      {
        font: [
          'Roboto',
          'Lora',
          'Lato',
          'Nunito',
          'Montserrat',
          'Poppins',
          'Raleway'
        ]
      }
    ],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['link', 'image', 'video'],
    [{ script: 'sub' }, { script: 'super' }],
    ['align', { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['clean']
  ]
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
]

const Edit = (props) => {
  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    gender: '',
    country: '',
    position: '',
    tel: '',
    address: '',
    bio: '',
    file: '',
    linkedinUrl:'',
    githubUrl:'',
    twitterUrl:'',
  }
  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const onChange = (e) => {
    const { name, value, type } = e.target
    if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }
  useEffect(() => {
    fetchEmployee()
  }, [])

  const fetchEmployee = async () => {
    setLoading(true)
    const { data } = await axios.get(`/api/users/${id}`)
    const {
      firstName,
      lastName,
      email,
      gender,
      country,
      position,
      tel,
      dateOfBirth,
      address,
      linkedinUrl,
      githubUrl,
      twitterUrl,
      bio
    } = data
    setFormData({
      firstName,
      lastName,
      email,
      gender,
      dateOfBirth,
      country,
      address,
      position,
      tel,
      bio,
      linkedinUrl,
      githubUrl,
      twitterUrl
    })
    setLoading(false)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const data = new FormData()
    for (const key in formData) {
      data.append(key, formData[key])
    }
    axios
      .put(`/api/users/${id}`, {...formData, avatar:formData.file?.filename})
      .then((response) => {
        console.log('response:', response)
        // props.setNotification(response.data.message)
        // setTimeout(() => {
        //   props.setNotification("")
        // }, 5000)
        navigate(`/users/${id}`)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (loading) {
    return <div>Loading . . .</div>
  }
  let dateOfBirth = moment(formData.dateOfBirth).format('YYYY-MM-DD')

  return (
    <Container className='d-flex flex-column align-items-center pt-4 mb-3'>
      <h3>Update data</h3>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <FormGroup>
              <Label forhtml='firstName'>First name</Label>
              <Input
                type='text'
                onChange={onChange}
                id='firstName'
                name='firstName'
                value={formData.firstName}
                className='form-control'
                placeholder='First name'
                aria-label='First name'
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='lastName'>Last name</Label>
              <Input
                type='text'
                onChange={onChange}
                id='lastName'
                name='lastName'
                value={formData.lastName}
                className='form-control'
                placeholder='Last name'
                aria-label='Last name'
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='email'>Email</Label>
              <Input
                onChange={onChange}
                name='email'
                id='email'
                value={formData.email}
                className='form-control'
                placeholder='Email'
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label forhtml='gender'>Gender</Label>
              <Input
                type='select'
                className='form-control'
                id='gender'
                onChange={onChange}
                name='gender'
                value={formData.gender}
                placeholder='Gender'
              >
                {[
                  { label: '--Select--', value: '' },
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Other', value: 'other' }
                ].map(({ label, value }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='dateOfBirth'>Date of Birth</Label>
              <Input
                type='date'
                className='form-control'
                placeholder='Date of birth'
                aria-label='First name'
                onChange={onChange}
                name='dateOfBirth'
                value={dateOfBirth}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='country'>Country</Label>
              <Input
                type='text'
                className='form-control'
                id='country'
                placeholder='Country'
                onChange={onChange}
                name='country'
                value={formData.country}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {' '}
            <FormGroup>
              <Label forhtml='position'>Position</Label>
              <Input
                type='text'
                className='form-control'
                placeholder='Position'
                onChange={onChange}
                name='position'
                value={formData.position}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='tel'>Tel</Label>
              <Input
                type='tel'
                id='tel'
                className='form-control'
                placeholder='tel'
                onChange={onChange}
                name='tel'
                value={formData.tel}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label forhtml='address' className='form-label'>
            Address
          </Label>
          <Input
            type='text'
            className='form-control'
            id='address'
            name='address'
            placeholder='1234 Main St'
            value={formData.address}
            autoComplete='off'
            onChange={onChange}
          />
        </FormGroup>

        <Row>
          <Col>
            <FormGroup>
              <Label forhtml='linkedinUrl'>LinkedIn</Label>
              <Input
                id='linkedinUrl'
                className='form-control'
                placeholder='Linkedin Url'
                onChange={onChange}
                name='linkedinUrl'
                value={formData.linkedinUrl}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='githubUrl'>GitHub</Label>
              <Input
                id='githubUrl'
                className='form-control'
                placeholder='GitHub URL'
                onChange={onChange}
                name='githubUrl'
                value={formData.githubUrl}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label forhtml='twitterUrl'>Twitter</Label>
              <Input
                id='twitterUrl'
                className='form-control'
                placeholder='Twitter URL'
                onChange={onChange}
                name='twitterUrl'
                value={formData.twitterUrl}
              />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup className='col mb-3'>
          <Label forhtml='bio' className='form-label'>
            Bio
          </Label>
          <Input
            type='textarea'
            className='form-control'
            id='bio'
            rows='3'
            onChange={onChange}
            name='bio'
            value={formData.bio}
          ></Input>
        </FormGroup>
        <FormGroup>
          <Label for='file' className='form-label'>
            Upload your image
          </Label>
          <input
            type='file'
            name='file'
            id='image'
            onChange={onChange}
            placeholder='Upload an image'
            accept='image/*'
            size='600'
          />
        </FormGroup>
        <div className='row my-25'>
          <div className='col'>
            <button className='btn btn-primary' type='submit'>
              Update
            </button>
          </div>
        </div>
      </Form>
    </Container>
  )
}

export default Edit
