import React, { Component, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import TextInputField from '../shared/TextInputField'
import './hexa-color-generator.scss'

// Hexadecimal color generator
const hexaColor = () => {
  let str = '0123456789abcdef'
  let color = ''
  for (let i = 0; i < 6; i++) {
    let index = Math.floor(Math.random() * str.length)
    color += str[index]
  }
  return '#' + color
}

const generateColors = (n) => {
  const colors = []
  for (let i = 0; i < n; i++) {
    colors.push(hexaColor())
  }
  return colors
}

const Color = ({ color, style }) => {
  const [copied, setCopied] = useState(false)
  const ref = useRef(null)
  const copyResult = () => {
    const content = ref.current.textContent
    navigator.clipboard.writeText(content)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }
  const status = copied ? 'copied' : 'copy'

  return (
    <div style={style} className='single-color'>
      <small
        ref={ref}
        style={{ fontWeight: '500', fontSize: '32px !important' }}
      >
        {' '}
        {color}
      </small>
      <div className={`wrapper__copy ${status}`}>
        <i
          style={{
            cursor: 'pointer',
            fontSize: 28,
            color: 'white',
          }}
          class='far fa-clipboard'
          onClick={copyResult}
        ></i>
      </div>
    </div>
  )
}

const Colors = ({ colors }) => {
  const colorList = colors.map((color) => {
    const style = {
      width: '160px',
      height: '160px',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Montserrat',
      letterSpacing: '0.0625em',
      justifyContent: 'space-around',
      alignItems: 'center',
      margin: '2px',
      borderRadius: '5px',
      fontSize: '22px',
      padding: 30,
      boxSizing: 'border-box',
      fontWeight: 100,
      background: color,
      color: '#efefef',
    }

    return <Color color={color} style={style} />
  })

  const flexStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
  }
  return (
    <div style={flexStyles} className='colors-wrapper'>
      {colorList}
    </div>
  )
}

const ColorGeneratorApp = () => {
  const initialColors = generateColors(27)
  const [colors, setColors] = useState(initialColors)
  const [value, setValue] = useState('')
  const onChange = (e) => {
    if (Number(e.target.value)) {
      setValue(e.target.value)
    }
  }
  const onClick = () => {
    const n = Number(value)
    if (value === '' || undefined) {
      setColors(generateColors(27))
    } else {
      setColors(generateColors(n))
    }
  }
  return (
    <div className='color-app'>
      <div
        style={{
          fontSize: '48px',
          fontWeight: 100,
          color: 'white',
          textAlign: 'center',
          margin: 'auto',
          color: 'black',
          fontFamily: 'Aldrich',
          fontWeight: 100,
          fontSize: 22,
        }}
      >
        <h1 style={{ fontSize: 60, margin: '20px 0 0 0', fontWeight: 500 }}>
          30 Days Of React
        </h1>
        <h2
          style={{
            fontSize: 40,
            margin: '0 0 30px 0',
            fontWeight: 300,
            fontFamily: 'Roboto',
          }}
        >
          Hexadecimal Colors
        </h2>
      </div>
      <div className='color-generator-row'>
        <TextInputField type='text' onChange={onChange} value={value} />
        <button onClick={onClick} className='generate-color-btn'>
          Generate
        </button>
      </div>

      <Colors colors={colors} />
    </div>
  )
}

export default ColorGeneratorApp
