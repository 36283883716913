import React, { useState, useContext } from 'react'
import { UserContext } from '../context/UserContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.snow.css'
import "react-quill/dist/quill.core.css"
import hljs from "highlight.js";
import { Navigate } from 'react-router-dom'
import { Container,Row } from 'reactstrap'
import '../assets/styles/create-post.scss'

hljs.configure({
  // optionally configure hljs
  languages: ["javascript", "python", "c", "c++", "java", "HTML", "css", "matlab"],
});

const modules = {
  syntax: {
    highlight: function (text) {
      return hljs.highlightAuto(text).value;
    },
  },
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: ['Roboto', 'Lora','Lato', 'Nunito', 'Montserrat', 'Poppins', 'Raleway'] }],
    [{ color: [] }, { background: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'code-block'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image', 'video'],
    [{ 'script': 'sub'}, { 'script': 'super' }], 
    ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    ['clean'],
  ],
}


const CreatePost = (props) => {
  const initialState = {
    title: '',
    subtitle: '',
    category: '',
    summary: '',
    content: '',
    file: '',
    video:{}
  }
  const [formData, setFormData] = useState(initialState)
  const [loading, setLoading] = useState(false)
  const {user, setUser } = useContext(UserContext)
  const [content, setContent] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [preview, setPreviw] = useState('')
  const [progress, setProgress] = useState(0);
  const [visible, setVisible] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploadButtonText, setUploadButtonText] = useState('Upload Video')
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData()
    data.append('title', formData.title)
    data.append('subtitle', formData.subtitle)
    data.append('summary', formData.summary)
    data.append('content', content)
    data.append('category', formData.category)
    data.append('file', formData.file)
    data.append('video', formData.video)
    try {
      const url = '/api/posts'
      const response = await fetch(url, {
        method: 'POST',
        body: data,
        credentials: 'include',
        onUploadProgress:(evt) => {
          const progress = (evt.loaded) / (evt.total) * 100
          setProgress(progress)
        }
      })
      if (response.ok) {
        setRedirect(true)
      }
      console.log(response)
    } catch (error) {
      console.log('Post create error', error)
    }
  }
  const handleVideo = async (e) => {
    try {
      const file = e.target.files[0]
      console.log(file)
      setUploadButtonText(file.name)
      setUploading(true)
   /*    const videoData = new FormData()
      videoData.append('video', file)
      const {data} = await axios.post(`/api/posts/video-upload`, videoData, {
        onUploadProgress: (e) => {
          setProgress(Math.round((100 * e.loaded) / e.total))
        }
      }) */
      setFormData({...formData, video:file })
    } catch (error) {
      
    }
  }
  if(!user) {
    return <Navigate to={'/login'} />
  }
  if (redirect) {
    return <Navigate to={'/'} />
  }


  return (
    <Container>
      <Row className='create'>
      <form onSubmit={onSubmit} enctype="multipart/form-data">
        <input
          type='text'
          name='title'
          value={formData.title}
          onChange={onChange}
          placeholder='Title'
        />
        <input
          type='text'
          name='subtitle'
          value={formData.subtitle}
          onChange={onChange}
          placeholder='Subtitle'
        />
         <textarea
          type='text'
          name='summary'
          value={formData.summary}
          onChange={onChange}
          placeholder='Summary'

        />
        <input
          type='text'
          name='category'
          value={formData.category}
          onChange={onChange}
          placeholder='Category'
        />
        <ReactQuill
          theme='snow'
          value={content}
          name='content'
          onChange={setContent}
          modules={modules}
          className='editor-container'
        />
        {/* <textarea name="content" id="" cols="90" rows="10"></textarea> */}
        <input
          type='file'
          onChange={(e) =>
            setFormData({ ...formData, file: e.target.files[0] })
          }
          accept='image/*'
        />
        
         {
          formData.file &&  <img
            src={URL.createObjectURL(formData.file)}
            alt=""
          />
         }
         <br />
         {
          progress > 0 && <progress>{progress}%</progress>
         }

        {/* <input
          type='file'
          accept='video/*'
          onChange = {handleVideo}
        /> */}
        <button style={{ marginTop: '5px' }}>Create Post</button>
      </form>
      </Row>
    </Container>
  )
}

export default CreatePost
