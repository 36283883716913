import React from "react";
import PropTypes from "prop-types";
import spinner from "./spinner.gif";
import spinnerStyles from './Spinner.module.css'

const Spinner = props => {
  return (
    <div className={spinnerStyles.spinner}>
      <img src={spinner} alt="Loading..."
        style={{
          width:200, margin:'auto', display:'block'
        }}/>
    </div>
  );
};

Spinner.propTypes = {};

export default Spinner;

/* const Spinner = props => {
  return (
    (
        <div className={spinnerStyles.spinner}>
          <i className='fa fa-solid fa-spinner fa-spin'></i>
        </div>
      )
  )
} */