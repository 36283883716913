import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import Moment from 'react-moment'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, Input } from 'reactstrap';
import { UserContext } from '../../../context/UserContext'
import { format, formatISO9075 } from 'date-fns'
import './UserTable.scss'

const UserTable = (props) => {
	const [searchTerm, setSearchTerm] = useState('')
	const {user:currentUser} = useContext(UserContext)
	console.log(currentUser)
	const filterUser = (term) => {
		const search = term.toLowerCase()
		return term  ? props.users.filter(({firstName, lastName, email, country, position}) => firstName.toLowerCase().includes(search) || lastName.toLowerCase().includes(search) || email.toLowerCase().includes(search) || country?.toLowerCase().includes(search) || position?.toLowerCase().includes(search) ): props.users
	}
	const deleteUser =  async (id) =>  {
		try {
			await axios.delete(`/api/users/${id}`)
			window.location.reload(true)
		} catch (error) {
			console.log(error)
			
		}
	
	}
	const data = filterUser(searchTerm)
	const rows = data.map((user, index)=> <tr key = {user.id} role='button'>
		 	<td>{index + 1}</td> 
			<td className=''> 
			<Link to={`/users/${user.id}`}>
			<img 
			src = {user.avatar} 
			className='avatar rounded-circle'
			alt="avatar"
			/>
			</Link>
			
			</td>
		<td>{user.firstName}</td>
		<td>{user.lastName}</td>
		<td>{user.email}</td>
		{/* <td>
			<Moment format="D MMM YYYY">{user.dateOfBirth}</Moment>
		</td> */}
		{/* <td>{user.gender  === 'male' ? <i className="bi bi-gender-male"></i> : <i className="bi bi-gender-female"></i>}</td> */}
		{/* <td>{user.country}</td> */}
		{/* <td>{user.position}</td> */}
		{/* <td>{user.salary}</td> */}
		{
			currentUser?.role[0] === 'Admin' && <td>
					<Link to = {`/users/edit/${user.id}`}> <i class="fas fa-solid fa-edit"></i></Link>
					 <i class="fas fa-solid fa-trash fa-trash-alt" onClick={() => deleteUser(user.id)}></i>
					
		</td>
		}
		</tr>
		
	)
	const headers = <thead>
	<tr>
	<th>No.</th>
		<th>Avatar</th>
		{/* <th>ID</th> */}
		<th>First Name</th>
		<th>Last Name</th>
		<th>Email</th>
		{/* <th>Date of Birth</th> */}
		{/* <th>Gender</th> */}
		{/* <th>Country</th> */}
		{/* <th>Position</th> */}
		{/* <th>Salary</th> */}
		{
			currentUser?.role[0] === 'Admin' && <th>Actions</th>
		}
		
	</tr>
	</thead>


	return (
		<Container>
	<div className='my-3'>
	<Input placeholder='Search user by name, email, position ...' onChange={(e) => setSearchTerm(e.target.value)}/>
	</div>
	<div className="table-responsive">
	<table className="table table-hover cursor">
	{headers}
		<tbody>
			{rows}
		</tbody>
	</table>

	</div>

		</Container>
	)
	
}



export default UserTable;
