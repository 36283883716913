import React from 'react'
import { Container, Row } from 'reactstrap'
import '../assets/styles/about.scss'

const AboutPage = () => {
  return (
    <Container className='about'>
      <Row id='introduction'>
        <h2>30 Days Of React Challenge</h2>
        <h3>Course Description:</h3>
        <p>
          Unleash your potential with our comprehensive "30 Days Of React
          Challenge." This meticulously designed course serves as your stepping
          stone into the world of React, equipping you with the skills and
          knowledge needed to excel in web development. With a strong focus on
          hands-on learning, you'll embark on a journey that will transform you
          into a proficient React developer.
        </p>

        <h3>Objectives:</h3>
        <ul class='ul-list'>
          <li class='list'>Understand the fundamental concepts of React.</li>
          <li class='list'>Gain proficiency in creating JSX Elements.</li>
          <li class='list'>Master the art of rendering JSX Elements.</li>
          <li class='list'>
            Learn to inject data into JSX elements for dynamic content.
          </li>
          <li class='list'>Explore styling and class management with React.</li>
          <li class='list'>Dive deep into the world of React Components.</li>
          <li class='list'>
            Develop a strong foundation in Functional and Class-based
            Components.
          </li>
          <li class='list'>
            Harness the power of Props to create versatile React applications.
          </li>
          <li class='list'>Understand the intricacies of managing States.</li>
          <li class='list'>Master event handling in React applications.</li>
          <li class='list'>Create dynamic and interactive forms.</li>
          <li class='list'>Implement conditional rendering techniques.</li>
          <li class='list'>
            Explore controlled and uncontrolled input elements.
          </li>
          <li class='list'>
            Dive into the Component Life Cycle for optimal application
            performance.
          </li>
          <li class='list'>
            Navigate through different routes with React Router.
          </li>
          <li class='list'>
            Unlock the potential of React Hooks for efficient state management.
          </li>
        </ul>

        <p>
          <strong>Author:</strong> Asabeneh Yetayeh
        </p>

        <p>
          This course is your gateway to becoming a proficient React developer,
          and upon completion, you'll be eligible to claim a certificate of
          achievement. By tackling a variety of complex applications, you'll not
          only bolster your knowledge but also prepare yourself for a future as
          a Junior Software Developer. Join us on this exciting journey of
          discovery and growth in the world of React!
        </p>
      </Row>
    </Container>
  )
}

export default AboutPage
