import React, { useState, useContext } from 'react'
import './Comment.scss'
import AddReply from './AddReply'
import Reply from './Reply'
import { format, formatISO9075 } from 'date-fns'
import { UserContext } from '../../../context/UserContext'

const Comment = ({ comment, addReply, likeComment, likeReply }) => {
  const { user } = useContext(UserContext)
  return (
    <>
      <div className={`comment-container comment`}>
        <div className='comment-box'>
          <div className='comment-box-header'>
            <div className='comment-user-info'>
              <img src={comment.user.avatar} />
              <small>{comment.user.firstName}</small>
            </div>
            <div>
              <i
                class='far fa-regular fa-heart'
                onClick={() => likeComment(comment._id)}
              ></i>{' '}
              {comment.likes.length > 0 && comment.likes.length}
              <i class='far fa-regular fa-comment'></i>{' '}
              {comment.replies.length > 0 && comment.replies.length}
            </div>

            <time>
              {format(new Date(comment.createdAt), 'MMM d, yyyy HH:mm')}
            </time>
          </div>
          <div>
            <p>{comment.content}</p>
          </div>

          {/* <button>Reply</button> */}
        </div>
      </div>
      {comment.replies.length > 0 &&
        comment.replies.map((reply) => (
          <Reply
            reply={reply}
            likeReply={likeReply}
            commentId={comment._id}
          />
        ))}
      {user && (
        <div className={`comment-container reply`}>
          <div className='comment-box'>
            <div className='comment-box-header'>
              <div className='comment-user-info'>
                <img src={user.avatar} />
                <small>{user.firstName}</small>
              </div>
            </div>

            <AddReply
              addReply={addReply}
              id={comment._id}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Comment
