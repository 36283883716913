import React, { useState, useEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Navigate,Link, useNavigate, useParams } from 'react-router-dom'
import { format, formatISO9075 } from 'date-fns'
import SocialShareButton from '../components/courses/social-share/SocialShareButton'
import { UserContext } from '../context/UserContext'
import Spinner from '../components/courses/spinner/Spinner'
import Comments from '../components/courses/comments/Comments'
// import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import jsx from 'react-syntax-highlighter/dist/esm/languages/prism/jsx'
import py from 'react-syntax-highlighter/dist/esm/languages/prism/python'
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript'
import { solarizedLight as theme } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {
  materialDark,
  materialLight,
  oneLight
} from 'react-syntax-highlighter/dist/cjs/styles/prism'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import axios from 'axios'
import singlePostStyles from './SinglePost.module.scss'
import { solarizedLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import Parser from 'html-react-parser'
// import 'highlight.js/styles/dark.css';

// SyntaxHighlighter.registerLanguage('jsx', jsx);
// SyntaxHighlighter.registerLanguage('javascript', js);
import './code-highlighter.css'
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import { Col, Container, Row } from 'reactstrap'
hljs.registerLanguage('javascript', javascript)
// hljs.registerLanguage('jsx', jsx)
// hljs.highlightAll()

const CodeBlock = ({ string }) => {
  const codeString = `${string}`
  return (
    <SyntaxHighlighter language='js' style={theme}>
      {codeString}
    </SyntaxHighlighter>
  )
}

const SinglePost = (props) => {
  const [post, setPost] = useState(null)
  const memoizedPost = useMemo(() => post)
  const [loading, setLoading] = useState(false)
  const [video, setVido] = useState({})
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [uploadButtonText, setUploadButtonText] = useState('Upload Video')
  const { user } = useContext(UserContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const isCompleted =
    user && user.completed.findIndex((item) => item.postId === id) !== -1

  useEffect(() => {
    fetchData()
  }, [])
  const fetchData = async () => {
    const url = `/api/posts/${id}`
    try {
      setLoading(true)
      const response = await fetch(url)
      const data = await response.json()
      setPost(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    addView()
  }, [])
  const addView = async () => {
    const url = `/api/posts/${id}/views`
    const response = await fetch(url, {
      method: 'POST'
    })
  }
  const deletePost = async () => {
    const url = `/api/posts/${id}`
    const response = await fetch(url, {
      method: 'DELETE'
    })
    if (response.ok) {
      navigate('/posts')
    }
  }
  // router.put('/:id/complete', completePost)
  const completeCourse = async () => {
    const url = `/api/posts/${id}/complete`
    const response = await fetch(url, {
      method: 'PUT'
    })
  }

  const likePost = async () => {
    const url = `/api/posts/${id}/likes`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    })
    fetchData()
  }
  console.log(post)
  if (!post && !loading) {
    return <Spinner />
  }

  return (
    <Row>
      <Col className='col'>
        {/* <h1>{post.title}</h1> */}
        <Row className='d-flex justify-content-between align-items-center'>
          <Col className='col-10'>
            <img
              src={post && post?.user?.avatar}
              alt={post && post?.user?.firstName}
              className='img-fluid me-2'
              style={{ width: '3%', borderRadius: '50%' }}
            />
            <span href=''>
              {post && post?.user?.firstName} {post && post?.user?.lastName}
            </span>
            <time className={singlePostStyles.singlePageTime}>
              {format(new Date(post && post.createdAt), 'MMM d, yyyy HH:mm')}
            </time>
          </Col>

          <Col className='col-2'>
            {user && (user?.role[0] === 'Admin' && user?.id === post?.user?.id) && (
              <div className='d-flex justify-content-between align-items-center'>
                <div className='edit-row'>
                  <Link to={`/posts/edit/${id}`} className='edit-btn'>
                    EDIT
                    <i className='far fa-solid fa-pencil'></i>
                    <i className='far fa-regular fa-pen-to-square'></i>
                    <i className='far far-regular fa-solid fa-pen'></i>
                    <i className='far fa-solid fa-pen'></i>
                  </Link>
                </div>
                <div>
                  <span onClick={deletePost}>DELETE</span>
                  <i className='far fa-regular fa-trash-can'></i>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row className='my-2'>
          <img src={post && post.cover.url} alt='' />
        </Row>

        <div className='ql-snow'>
          <div
            dangerouslySetInnerHTML={{ __html: post && post.content }}
            className='content ql-editor'
          />
        </div>

        {/* <div className="ql-snow">
      <div className="ql-editor hljs-keyword">
      <ReactMarkdown
        children={post.content}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      /> 
      </div>
    </div> */}

        { (isCompleted && user ) && (
          <span
            onClick={completeCourse}
            className={singlePostStyles.statusCompleted}
          >
            Completed
          </span>
        )}
        {(!isCompleted && user) && (
          <span
            onClick={completeCourse}
            className={singlePostStyles.statusComplete}
          >
            Complete
          </span>
        )}
        <div>
          <div className={singlePostStyles.postStat}>
            <span>
              {' '}
              <i
                className='far fa-regular fa-heart'
                onClick={() => likePost()}
              ></i>{' '}
              {post && post.likes.length > 0 && post.likes.length}
            </span>
            <span>
              {' '}
              <i className='far fa-regular fa-comment'></i>{' '}
              {post && post.comments.length > 0 && post.comments.length}
            </span>
            <span>
              {post && post.views && (
                <>
                  <i className='far fa-regular fa-eye'></i>
                  {post.views}
                </>
              )}
            </span>
          </div>
          <div className={singlePostStyles.socials}>
            <h3>Share on</h3>
            <SocialShareButton
              category={'posts'}
              id={post && post.id}
              hashtag='30 Days Of React'
            />
          </div>

          <div>
            <h2>Comments</h2>
            <Comments id={id} comments={post && post.comments} />
          </div>
        </div>
      </Col>
    </Row>
  )
}

SinglePost.propTypes = {}

export default SinglePost
