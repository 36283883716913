import React from 'react'
import PropTypes from 'prop-types'
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import socialShareButtonStyles from './SocialShareButton.module.css'

export const SocialButton = ({
  Component,
  Icon,
  url,
  quote,
  hashtag,
  className,
  size = 50,
}) => {
  return (
    <>
      <Component
        url={url}
        quote={quote}
        hashtag={hashtag}
        className={className}
      >
        <Icon size={size} round={true} />
      </Component>
    </>
  )
}

const SocialShareButton = ({ category, id, hashtag }) => (
  <div className={socialShareButtonStyles.socialIcons}>
    <SocialButton
      Component={FacebookShareButton}
      url={`https://www.30daysofreact.com/${category}/${id}`}
      quote={'Social media text to be displayed'}
      hashtag={hashtag}
      Icon={FacebookIcon}
    />{' '}
    <SocialButton
      Component={TwitterShareButton}
      url={`https://www.30daysofreact.com/${category}/${id}`}
      quote={'Social media text to be displayed'}
      hashtag={hashtag}
      Icon={TwitterIcon}
    />{' '}
    <SocialButton
      Component={LinkedinShareButton}
      url={`https://www.30daysofreact.com/${category}/${id}`}
      quote={'Social media text to be displayed'}
      hashtag={hashtag}
      Icon={LinkedinIcon}
    />
    <SocialButton
      Component={TelegramShareButton}
      url={`https://www.30daysofreact.com/${category}/${id}`}
      quote={'Social media text to be displayed'}
      hashtag={hashtag}
      Icon={TelegramIcon}
    />{' '}
    <SocialButton
      Component={WhatsappShareButton}
      url={`https://www.30daysofreact.com/${category}/${id}`}
      quote={'Social media text to be displayed'}
      hashtag={hashtag}
      Icon={WhatsappIcon}
    />{' '}
  </div>
)

SocialShareButton.propTypes = {
  Component: PropTypes.element,
  url: PropTypes.string,
  quote: PropTypes.string,
  hashtag: PropTypes.string,
  Icon: PropTypes.element,
  className: PropTypes.string,
  size: PropTypes.number,
}

export default SocialShareButton
