import { faker } from '@faker-js/faker';
import { CountriesObject } from './countries-obj'
import { capitalCities } from './capital-cities'

const sortCountries = (arr, type = '') => {
  const countries = [...arr]
  return countries.sort(function (a, b) {
    if (a > b) return 1
    if (a[type] > b[type]) return -1
    if (a[type] < b[type]) return 1

    return 0
  })
}

const simpleCountriesObject = () => {
  const url = 'https://restcountries.eu/rest/v2'
  fetch(url)
    .then((response) => response.json())
    .then((data) => {
      let countries = []
      data.forEach((country, i) => {
        const langs = []
        let { name, capital, languages, population, currencies, flag } = country
        for (const l of languages) {
          langs.push(l.name)
        }
        countries.push({
          name,
          capital,
          languages: langs,
          population,
          flag,
          currency: currencies[0].name,
        })
      })
    })
}

export const arrayOfCountries = (arr, number) => arr.slice(0, number)
export const arrayOfCapitalCities = (arr, num) =>
  sortCountries(arr).slice(0, num)
export const arrayOfCountriesObject = (arr, num) => arr.slice(0, num)
export const arrayOfRandomCountriesObject = (arr, num) => {
  const randomCountries = []
  let randIndex
  while (randomCountries.length <= num) {
    randIndex = Math.floor(Math.random() * arr.length)
    randomCountries.push(arr[randIndex])
  }
  return randomCountries
}

export const arrayOfRandomCountries = (arr, num) => {
  if (num > 198) return 'Number should be less than 199'
  const randomCountries = []
  let randIndex
  while (randomCountries.length <= num) {
    randIndex = Math.floor(Math.random() * arr.length)
    randomCountries.push(arr[randIndex])
  }
  return randomCountries
}
export const arrayOfRandomCapitalCities = (arr, n) => {
  const cities = []

  for (let i = 0; i < n; i++) {
    let index = Math.floor(Math.random() * arr.length)
    cities.push(arr[index])
  }
  return cities
}
export const arrayOfAvatars = (number) => {
  let avatars = []
  let avatar
  for (let i = 0; i < number; i++) {
    avatar = faker.fake('{{image.avatar}}')
    avatars.push(avatar)
  }
  return avatars
}
export const arrayOfImages = (number) => {
  const images = []
  let image
  for (let i = 0; i < number; i++) {
    image = faker.random.image()
    images.push(image)
  }
  return images
}

export const arrayOfFoods = (number) => {
  const foodArray = []
  for (let i = 0; i < number; i++) {
    const name = faker.fake('{{lorem.word}}')
    const description = faker.fake('{{lorem.words}}')
    const recipe = faker.fake('{{lorem.sentence}}').split(' ')
    const price = Math.floor(Math.random() * 21)
    const image = faker.fake('{{image.food}}')
    foodArray.push({ name, description, recipe, price, image })
  }
  return foodArray
}

export const arrayOfCats = (number) => {
  const cats = []
  for (let i = 0; i < number; i++) {
    const name = faker.fake('{{lorem.word}}')
    const breed = faker.fake('{{lorem.words}}')
    const age = Math.floor(Math.random() * 21)
    const color = faker.fake('{{lorem.word}}')
    const image = faker.fake('{{image.cats}}')
    cats.push({ name, breed, age, color, image })
  }
  return cats
}

export const arrayOfAnimals = (number) => {
  const animals = []
  for (let i = 0; i < number; i++) {
    const name = faker.fake('{{lorem.word}}')
    const breed = faker.fake('{{lorem.words}}')
    const age = Math.floor(Math.random() * 21)
    const color = faker.fake('{{lorem.word}}')
    const image = faker.fake('{{image.animals}}')
    animals.push({ name, breed, age, color, image })
  }
  return animals
}
export const arrayOfStories = (number) => {
  const stories = []
  for (let i = 0; i < number; i++) {
    let title = faker.fake('{{lorem.word}}')
    let subtitle = faker.fake('{{lorem.words}}')
    let author = faker.fake('{{lorem.words}}')
    let pages = Math.floor(Math.random() * 81) + 20
    let paragraphs = faker.fake('{{lorem.paragraphs}}')
    stories.push({ title, subtitle, pages, author, paragraphs })
  }
  return stories
}

export const arrayOfSports = (number) => {
  const sports = []
  for (let i = 0; i < number; i++) {
    let name = faker.fake('{{lorem.word}}')
    let description = faker.fake('{{lorem.words}}')
    let category = faker.fake('{{lorem.words}}')
    let image = faker.fake('{{image.sports}}')
    sports.push({ name, description, category, image })
  }
  return sports
}

//
export const genHexaColor = () => {
  const str = '0123456789abcdef'.split('')
  let hexa = '#'
  for (let i = 0; i < 6; i++) {
    let index = Math.floor(Math.random() * str.length)
    hexa += str[index]
  }
  return hexa
}

export const arrayOfRGBColors = (number) => {
  let red, green, blue, rgb
  const colors = []
  for (let i = 0; i < number; i++) {
    red = Math.floor(Math.random() * 256)
    green = Math.floor(Math.random() * 256)
    blue = Math.floor(Math.random() * 256)
    rgb = `rgb(${red},${green},${blue})`
    colors.push(rgb)
  }
  return colors
}

export const arrayOfHexaColors = (number) => {
  const colors = []
  for (let i = 0; i < number; i++) {
    colors.push(genHexaColor())
  }
  return colors
}
export const genRandomId = (n = 6) => {
  const str = '0123456789abcdefghijklmnopqrstuvwzyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let id = ''
  for (let i = 0; i < n; i++) {
    const index = Math.floor(Math.random() * str.length)
    id += str[index]
  }
  return id
}
const genRandomIp = () => {
  let ip, first, second, third, fourth
  for (let i = 0; i < 4; i++) {
    first = Math.floor(Math.random() * 256)
    second = Math.floor(Math.random() * 256)
    third = Math.floor(Math.random() * 256)
    fourth = Math.floor(Math.random() * 256)
    ip = `${first}:${second}:${third}:${fourth}`
  }
  return ip
}
export const arrayOfIPs = (number) => {
  const ips = []
  for (let i = 0; i < number; i++) ips.push(genRandomIp())
  return ips
}

export const arrayOfRandomIds = (number) => {
  const randomIds = []
  for (let i = 0; i < number; i++) {
    randomIds.push(genRandomId())
  }
  return randomIds
}

export const arrayOfNumbers = (number) => {
  let numbers = []
  for (let i = 0; i <= number; i++) numbers.push(i)
  return numbers
}

export const arrayOfEvenNumbers = (number) => {
  const evens = []
  let count = 0
  while (evens.length <= number) {
    if (count % 2 === 0) {
      evens.push(count)
    }
    count++
  }

  return evens
}

export const arrayOfOddNumbers = (number) => {
  const odds = []
  let count = 0
  while (odds.length <= number) {
    if (count % 2 !== 0) {
      odds.push(count)
    }
    count++
  }
  return odds
}
export const sumOfEvens = (number) => {
  let sum = 0
  for (let i = 0; i <= number; i++) {
    if (i % 2 === 0) {
      sum += i
    }
  }
  return sum
}
export const arrayOfEvens = (number) => {
  const evens = []
  for (let i = 0; i <= number; i++) {
    if (i % 2 === 0) {
      evens.push(i)
    }
  }
  return evens
}

export const arrayOfOdds = (number) => {
  const odds = []
  for (let i = 0; i <= number; i++) {
    if (i % 2 !== 0) {
      odds.push(i)
    }
  }
  return odds
}

export const sumOfOdds = (number) => {
  let sum = 0
  for (let i = 0; i <= number; i++) {
    if (i % 2 !== 0) {
      sum += i
    }
  }
  return sum
}

export const sumOfNumbers = (number) => {
  let sum = 0
  for (let i = 0; i <= number; i++) {
    sum += i
  }
  return sum
}

export const isPrime = (n) => {
  let isPrime
  for (let i = 2; i < n; i++) {
    if (n === 2) {
      isPrime = true
      break
    } else if (n % i === 0) {
      isPrime = false
      break
    } else {
      isPrime = true
    }
  }
  return isPrime
}
export const arrayOfPrimeNumbers = (number) => {
  const primes = [2]
  let count = 2
  while (primes.length < number) {
    if (isPrime(count)) {
      primes.push(count)
    }
    count++
  }

  return primes
}
export const factorial = (n) => {
  let f = 1
  if (n === 0 || n === 1) return f
  for (let i = 1; i <= n; i++) {
    f *= i
  }
  return f
}

export const arrayOfObjects = (number) => {
  const persons = []
  for (let i = 0; i < number; i++) {
    let id = genRandomId(6)
    let firstName = faker.fake('{{name.firstName}}')
    let lastName = faker.fake('{{name.lastName}}')
    let title = faker.fake('{{name.title}}')
    let jobTitle = faker.fake('{{name.jobTitle}}')
    let avatar = faker.fake('{{image.avatar}}')

    let country = faker.fake('{{address.country}}')
    let age = Math.floor(Math.random() * 73) + 18
    let phone = faker.fake('{{phone.phoneNumberFormat}}')
    let email =
      firstName.trim().toLowerCase() +
      '.' +
      lastName.trim().toLowerCase() +
      '@' +
      'cfeo.io'
    persons.push({
      id,
      firstName,
      lastName,
      title,
      jobTitle,
      avatar,
      age,
      country,
      email,
      phone,
    })
  }
  return persons
}

export const arrayOfNames = (number) => {
  let name
  let names = []
  for (let i = 0; i < number; i++) {
    name = faker.name.findName()
    names.push(name)
  }
  return names
}

export const arrayOfEmails = (number) => {
  let email
  let emails = []
  let firstName
  let lastName
  for (let i = 0; i < number; i++) {
    let name = faker.name.findName()
    let formatEmail = name.split(' ')
    firstName = formatEmail.length === 2 ? formatEmail[0] : formatEmail[1]
    lastName = formatEmail.length > 2 ? formatEmail[2] : formatEmail[1]
    email =
      firstName.trim().toLowerCase() +
      '.' +
      lastName.trim().toLowerCase() +
      '@' +
      'cfeo.io'
    emails.push(email)
  }
  return emails
}

// export const arrayOfCountries = (arr, number) => {
//   const countriesList = []
//   let randomIndex
//   for (let i = 0; i < number; i++) {
//     randomIndex = Math.floor(Math.random() * arr.length)
//     countriesList.push(arr[randomIndex])
//   }
//   return countriesList
// }

export const solveQuad = (a, b, c) => {
  const determinant = b ** 2 - 4 * a * c
  let x1, x2
  let solutionSet = new Set()
  if (determinant === 0) {
    x1 = -b / (2 * a)
    solutionSet.add(x1)
    return solutionSet
  } else if (determinant > 0) {
    x1 = (-b + Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a)
    x2 = (-b - Math.sqrt(b ** 2 - 4 * a * c)) / (2 * a)
    solutionSet.add(x1)
    solutionSet.add(x2)
    return solutionSet
  } else {
    return solutionSet
  }
}
