import React from 'react'
import Post from './Post'
import {Container, Row} from 'reactstrap'
const Posts = ({posts}) => {
    const postList = posts.map((post) => <Post key={post.id} {...post} />)
    return (
        <Container>
       <Row>
       {postList}
       </Row>
        </Container>
    )
}

export default Posts