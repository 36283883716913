import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import User from './User'
import UserTable from './UserTable'
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

const Users = (props) => {
  const [users, setUsers] = useState([])
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()

  const getUsers = async () => {
    const response = await axios.get('/api/users')
    setUsers(response.data)
  }
  useEffect(() => {
    if (user && user.role[0] === 'Admin') {
      getUsers()
    } else {
      navigate('/')
    }
  }, [navigate, user])
  if (users.length < 0) {
    return <div>loading</div>
  }

/*   const userList =
    users && users.map((user) => <UserTable key={user.id} user={user} />) */

    const userList = <UserTable users = {users} />

  return (
    <div className='container'>
      <div className='d-flex flex-row-reverse'>
        <NavLink to='/users/table'>
          <i className='bi bi-table p-2 bi-10x' role='button'></i>
        </NavLink>
        <NavLink to='/users/card'>
          {' '}
          <i className='bi bi-card-heading p-2 bi-10x' role='button'></i>
        </NavLink>
      </div>
      <div>
        <h3>Number of users ({users.length})</h3>
       <div className='my-3'>
       {userList}
       </div>
      </div>
    </div>
  )
}

export default Users