import React, { useEffect, useState, useContext } from 'react'
import Comment from './Comment'
import AddComment from './AddComment'
import axios from 'axios'
import { UserContext } from '../../../context/UserContext'

const Comments = ({ id, comments }) => {
  const { user } = useContext(UserContext)
  const addComment = async (comment) => {
    try {
      const url = `/api/posts/${id}/comments`
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ content: comment }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
    } catch (error) {
      console.log(error)
    }
  }
  const addReply = async (commentId, comment) => {
    try {
      const url = `/api/posts/${id}/comments/${commentId}`
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ content: comment }),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
    } catch (error) {
      console.log(error)
    }
  }
  const likeComment = async (commentId) => {
    const url = `/api/posts/${id}/comments/${commentId}/likes`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
  }
  const likeReply = async (commentId, replyId) => {
    const url = `/api/posts/${id}/comments/${commentId}/replies/${replyId}/likes`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
  }
  return (
    <div>
      {user && <AddComment addComment={addComment} />}
      {comments &&
        comments.map((comment) => (
          <div>
            <Comment
              comment={comment}
              addReply={addReply}
              likeComment={likeComment}
              likeReply={likeReply}
              type='comment'
            />
          </div>
        ))}
    </div>
  )
}

export default Comments
