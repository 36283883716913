import React from 'react'
import Header from '../header/Header'
import { Outlet } from 'react-router-dom'
import {Container,Row} from 'reactstrap'
import layoutStyles from './Layout.module.css'

const Layout = () => (
  <>
   <Header />
   <main>
   <Outlet />
   </main>
  </>
)

export default Layout
