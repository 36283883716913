import React, { useState, useRef } from 'react'
import TextInputField from '../shared/TextInputField'
import TextAreaField from '../shared/TextAreaField'
import SelectListGroup from '../shared/SelectListGroup'
import { countriesObject } from './countries-obj'
import { capitalCities } from './capital-cities'
import { countries } from './countries'
import './dummy-data.scss'
import {
  arrayOfHexaColors,
  arrayOfRandomIds,
  arrayOfRGBColors,
  arrayOfCapitalCities,
  arrayOfCountries,
  arrayOfRandomCapitalCities,
  arrayOfEvenNumbers,
  arrayOfNames,
  arrayOfAnimals,
  arrayOfAvatars,
  arrayOfCats,
  arrayOfOdds,
  arrayOfOddNumbers,
  arrayOfEmails,
  arrayOfPrimeNumbers,
  factorial,
  arrayOfImages,
  arrayOfCountriesObject,
  arrayOfObjects,
  arrayOfNumbers,
  sumOfEvens,
  sumOfNumbers,
  sumOfOdds,
  arrayOfEvens,
  arrayOfIPs,
  arrayOfFoods,
  arrayOfSports,
  arrayOfStories,
  arrayOfRandomCountries,
  arrayOfRandomCountriesObject,
} from './dummy-generators'
import { Col, Container, Row } from 'reactstrap'

const options = [
  { label: 'Hexa color', value: 'Hexa color' },
  { label: 'RGB color', value: 'RGB color' },
  { label: 'Random id', value: 'Random id' },
  { label: 'Random IP', value: 'Random IP' },
  { label: 'Prime numbers', value: 'Prime numbers' },
  { label: 'Odd numbers(1 - n)', value: 'Odd numbers(1 - n)' },
  { label: 'Even numbers(0 - n)', value: 'Even numbers(0 - n)' },
  {
    label: 'Sum of even numbers(0 - n)',
    value: 'Sum of even numbers(0 - n)',
  },
  { label: 'Sum of odd numbers(1 - n)', value: 'Sum of odd numbers(1 - n)' },
  { label: 'Sum of numbers', value: 'Sum of numbers' },
  { label: 'Factorial', value: 'Factorial' },
  { label: 'Names', value: 'Names' },
  { label: 'Numbers', value: 'Numbers' },
  { label: 'Emails', value: 'Emails' },
  { label: 'Array of person objects', value: 'Array of person objects' },
  { label: 'Array of countries', value: 'Array of countries' },
  { label: 'Array of random countries', value: 'Array of random countries' },
  { label: 'Array of capital cities', value: 'Array of capital cities' },
  {
    label: 'Array of random capital cities',
    value: 'Array of random capital cities',
  },
  { label: 'Array of country objects', value: 'Array of country objects' },
  {
    label: 'Array of random countries object',
    value: 'Array of random countries object',
  },
  { label: 'Array of animals object', value: 'Array of animals object' },
  { label: 'Array of cats object', value: 'Array of cats object' },
  { label: 'Array of foods object', value: 'Array of foods object' },
  { label: 'Array of sports object', value: 'Array of sports object' },
  { label: 'Array of stories object', value: 'Array of stories object' },
  { label: 'Website URL', value: 'Website URL' },
  { label: 'Images', value: 'Images' },
  { label: 'Avatars', value: 'Avatars' },
  //   {
  //     label: 'Solve Quadratic Equation(a,b,c)',
  //     value: 'Solve Quadratic Equation(a,b,c)',
  //   },
]

const DummyDataGenerator = (props) => {
  const [formData, setFormData] = useState({
    amount: '',
    select: 'Hexa color',
    result: '',
    touched: {
      amount: false,
    },
  })
  const [copySuccess, setCopySuccess] = useState('')
  const [copied, setCopied] = useState(false)
  const [textareaColor, setTextareaColor] = useState(false)
  const [feedback, setFeedback] = useState('')
  const textAreaRef = useRef(null)
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const copyResult = () => {
    navigator.clipboard.writeText(formData.result)
    setCopied(true)
    setTextareaColor(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }
  const onBlur = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, touched: { amount: true } })
  }

  const validate = () => {
    const errors = {
      feedback: '',
    }
    if (formData.touched.amount && !formData.amount) {
      errors.feedback = 'A number is required for this field'
    }

    return errors
  }

  const generate = () => {
    let result
    let amount = Number(formData.amount)
    let select = formData.select
    setTextareaColor(false)
    if (!formData.amount) {
      setFormData({ ...formData, touched: { amount: true } })
    }
    switch (select) {
      case 'Hexa color':
        const hexaColors = arrayOfHexaColors(amount)
        result = JSON.stringify(hexaColors, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'RGB color':
        const rgbColors = arrayOfRGBColors(amount)
        result = JSON.stringify(rgbColors, undefined, 4)
        setFormData({ ...formData, result })
        break

      case 'Random id':
        const randomIds = arrayOfRandomIds(amount)
        result = JSON.stringify(randomIds, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Random IP':
        const ips = arrayOfIPs(amount)
        result = JSON.stringify(ips, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Prime numbers':
        const primeNumbers = arrayOfPrimeNumbers(amount)
        result = JSON.stringify(primeNumbers, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Odd numbers(1 - n)':
        const oddNumbers = arrayOfOddNumbers(amount)
        result = JSON.stringify(oddNumbers, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Even numbers(0 - n)':
        const evns = arrayOfEvenNumbers(amount)
        result = JSON.stringify(evns, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Sum of even numbers(0 - n)':
        const evens = sumOfEvens(amount)
        result = JSON.stringify(evens, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Sum of odd numbers(1 - n)':
        const odds = sumOfOdds(amount)
        result = JSON.stringify(odds, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Sum of numbers':
        const sum = sumOfNumbers(amount)
        result = JSON.stringify(sum, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Factorial':
        const factorials = factorial(amount)
        result = JSON.stringify(factorials, undefined, 4)
        setFormData({ ...formData, result })
        break

      case 'Names':
        const names = arrayOfNames(amount)
        result = JSON.stringify(names, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Numbers':
        const numbers = arrayOfNumbers(amount)
        result = JSON.stringify(numbers, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Emails':
        const emails = arrayOfEmails(amount)
        result = JSON.stringify(emails, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of person objects':
        const persons = arrayOfObjects(amount)
        result = JSON.stringify(persons, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of countries':
        const c = arrayOfCountries(countries, amount)
        result = JSON.stringify(c, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of random countries':
        const arrC = arrayOfRandomCountries(countries, amount)
        result = JSON.stringify(arrC, undefined, 4)
        setFormData({ ...formData, result })
        break

      case 'Array of capital cities':
        const cities = arrayOfCapitalCities(capitalCities, amount)
        result = JSON.stringify(cities, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of random capital cities':
        const arrayOfRandomCities = arrayOfRandomCapitalCities(
          capitalCities,
          amount
        )
        result = JSON.stringify(arrayOfRandomCities, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of country objects':
        const countryObjects = arrayOfCountriesObject(countriesObject, amount)
        result = JSON.stringify(countryObjects, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of random countries object':
        const randomCobj = arrayOfRandomCountriesObject(countriesObject, amount)
        result = JSON.stringify(randomCobj, undefined, 4)
        setFormData({ ...formData, result })
        break

      case 'Array of animals object':
        const animals = arrayOfAnimals(amount)
        result = JSON.stringify(animals, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of cats object':
        const cats = arrayOfCats(amount)
        result = JSON.stringify(cats, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of foods object':
        const foods = arrayOfFoods(amount)
        result = JSON.stringify(foods, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of sports object':
        const sports = arrayOfSports(amount)
        result = JSON.stringify(sports, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Array of stories object':
        const stories = arrayOfStories(amount)
        result = JSON.stringify(stories, undefined, 4)
        setFormData({ ...formData, result })
        break

      case 'Images':
        const images = arrayOfImages(amount)
        result = JSON.stringify(images, undefined, 4)
        setFormData({ ...formData, result })
        break
      case 'Avatars':
        const avatars = arrayOfAvatars(amount)
        result = JSON.stringify(avatars, undefined, 4)
        setFormData({ ...formData, result })
        break
      default:
        setFormData({ ...formData, result: '' })
        break
    }
  }

  const status = copied ? 'copied' : 'copy'
  const textAreaBg =
    textareaColor && formData.result.length > 0 ? 'text-area-bg' : ''
  const errors = validate()
  return (
    <Container>
      <div className='dummy-data-container'>
        <div>
          <h2 className='data-generator-title'> Dummy Data generator</h2>
          <div className='banner'>
            <p>
              Using this application you can create array of numbers, odd
              numbers, even numbers, id, IP, colors, persons, countries, emails,
              names, cities,cats, foods, stories, and sports objects. Check all
              the possibilities you can by clicking the drop down button.
            </p>
          </div>
          <div>
            <Row>
            <Col>  <TextInputField
                type='text'
                name='amount'
                placeholder='Enter a number'
                value={formData.amount}
                onChange={onChange}
                onBlur={onBlur}
              /></Col>
              <Col>
              <SelectListGroup
                options={options}
                value={formData.select}
                name='select'
                onChange={onChange}
              />
              </Col>

            
              <div></div>
            </Row>
            {errors.feedback && (
              <p className='generator-feedback'>{errors.feedback}</p>
            )}
            <div>
              <button onClick={generate} className='generate-btn'>
                Generate
              </button>
            </div>
          </div>
        </div>

        <div className='textarea-wrapper'>
          <div className='wrapper__text'>
            <TextAreaField
              className={'color-text-area ' + textAreaBg}
              name='result'
              spellcheck='false'
              onChange={onChange}
              value={formData.result}
            />
          </div>
          <div className={`wrapper__copy ${status}`}>
            <i className='fas fa-copy' onClick={copyResult}></i>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default DummyDataGenerator
