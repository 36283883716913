import React, { useContext, useState } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from '../../context/UserContext'
import authStyles from './Auth.module.scss'
import classnames from 'classnames'
import TextInputField from '../../components/shared/TextInputField'

export const Login = () => {
  const initialState = {
    email: '',
    password: ''
  }
  const [formData, setFormData] = useState(initialState)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const { setUser } = useContext(UserContext)
  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    try {
      const url = '/api/users/login'
      const { data } = await axios.post(url, formData, {
        withCredentials: true
      })

      setUser(data)
      setLoggedIn(true)
    } catch (error) {
      setErrors(error.response.data)
      console.log('Login error', error)
    }
  }

  if (loggedIn) {
    return <Navigate to={'/'} />
  }

  return (
    <div className={`${authStyles.authWrapper} auth`}>
      <form onSubmit={onSubmit} className={authStyles.login}>
        <h1>Log In</h1>
        <TextInputField
          type='text'
          name='email'
          value={formData.email}
          onChange={onChange}
          placeholder='Email'
          error={errors.email}
        />
        <TextInputField
          type='password'
          name='password'
          value={formData.password}
          onChange={onChange}
          placeholder='Password'
          error={errors.password}
        />
        <button>Login</button>
      </form>
    </div>
  )
}

export default Login
