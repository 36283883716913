import React, { useState } from 'react'

const AddReply = ({ id, addReply }) => {
  const [reply, setReply] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    addReply(id, reply)
    setReply('')
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='add-comment'>
        <textarea
          id='comment'
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          required
        />
      </div>
      <button type='submit'>Reply</button>
    </form>
  )
}

export default AddReply
