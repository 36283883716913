import React, { useState } from 'react'
import './AddComment.scss'

const AddComment = ({ addComment }) => {
  const [comment, setComment] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    addComment(comment)
    setComment('')
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='add-comment'>
        {/* <label htmlFor='comment'>Comment:</label> */}
        <textarea
          id='comment'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder='Leave a comment ...'
          required
        />
      </div>
      <button
        type='submit'
        className='add-comment-btn'
      >
        Add Comment
      </button>
    </form>
  )
}

export default AddComment
