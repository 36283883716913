import React from 'react'
import { Link } from 'react-router-dom'
import htmLogo from '../assets/images/html_logo.png'
import cssLogo from '../assets/images/css_logo.png'
import jsLogo from '../assets/images/js_logo.png'
import ejsLogo from '../assets/images/react_logo.png'

import Projects from '../Projects'
import Courses from './git-repos/GitRepos'
import averageCatWeight from '../assets/images/average_cat_weight_and_age.png'
import numberGenerator from '../assets/images/day_6_number_generater_exercise.png'
import hexadecimal from '../assets/images/day_6_hexadecimal_colors_exercise.png'
import hexadecimal_color_props from '../assets/images/hexadecimal_color_exercise.png'
import newLetter from '../assets/images/news_letter_design.png'
import tenMostPopulatedCountries from '../assets/images/day_6_ten_highest_populations_exercise.png'
import reactEvenOnMouse from '../assets/images/react_event_on_mouse_enter.gif'
import changeBackground from '../assets/images/08_day_changing_background_exercise.gif'
import hexadecimalColorCopy from '../assets/images/day_27_hexadecimal_color_generator.png'
import getCountryProject from '../assets/images/08_day_select_country_exercise.gif'
import userCard from '../assets/images/user_card_design.png'
import twitterClone from '../assets/images/twitter_clone.png'
import cat19 from '../assets/images/day_19_cat.png'
import cat20 from '../assets/images/day_20_cat.png'
import countriesData from '../assets/images/countries_data.png'
import thirtyDaysOfReact from '../assets/images/30_days_of_react.jpg'
import { Container } from 'reactstrap'

const styles = {
  maxWidth: '100%',
  display: 'block',
  margin: '30px auto',
}

const categories = [
  {
    category: '',
    text: 'Cat Project 1',
    top: '',
    link: '/day-19/cats',
    courses: [],
    color: 'gray',
    day: 19,
  },
  {
    category: 'Cat Project 2',
    text: '',
    top: '',
    link: '/day-20/cats',
    courses: [],
    color: 'gray',
    day: 20,
  },
  {
    category: 'Basics of Command Line',
    text: '',
    top: '',
    link: '/day-23/countries-data',
    courses: [],
    color: 'gray',
    day: 24,
  },
  {
    category: 'Basics of Command Line',
    text: '',
    top: '',
    link: '/day-27/hexadecimal-colors',
    courses: [],
    color: 'gray',
    day: 27,
  },
  {
    category: 'HTML',
    text: '',
    top: 'Getting Started React',
    link: '/day-28/twitter-clone',
    courses: [],
    color: '#e34c26',
    day: 28,
  },
  {
    category: 'CSS',
    text: 'Content is not available yet',
    top: 'Setting Up',
    link: '',
    courses: [],
    color: '#264de4',
    day: 3,
  },
  {
    category: 'Git and GitHub',
    text: 'This section is not available yet',
    top: 'Components',
    link: '',
    courses: [],
    color: '#211F1F',
    day: 4,
  },
  {
    category: 'JavaScript',
    text: 'This section is not available yet',
    link: '',
    color: '#F0DB4F',
    day: 5,
    courses: [
      {
        name: '30DaysOfJavaScript',
        link: '/course/js/thirtydaysofjavascript',
        color: '#F0DB4F',
      },
      {
        name: 'JavaScript for Everyone',
        link: '/course/js/thirtydaysofjavascript',
        color: '#F0DB4F',
      },
    ],
  },
  {
    category: 'React',
    text: 'This section is not available yet',
    link: '',
    color: '#61DBFB',
    day: 6,
    courses: [
      {
        name: 'React for Everyone',
        link: '/course/react/reactforeveryone',
        color: '#61DBFB',
      },
    ],
  },
  ,
  {
    category: 'Python',
    text: 'This section is not available yet',
    link: '',
    color: '#4B8BBE',
    day: 7,
    courses: [
      {
        name: '30DaysOfPython',
        link: '/course/python/thirtydaysofpython',
        color: '#4B8BBE',
      },
      {
        name: 'Python for Everyone',
        link: '/course/python/pythonforeveryone',
        color: '#4B8BBE',
      },
    ],
  },

  {
    category: 'Node',
    text: 'This section  is not yet available',
    link: '',
    courses: [],
    color: '#3C873A',
    day: 8,
  },
  {
    category: 'Projects',
    text: 'Projects section  is not yet available',
    link: '',
    courses: [],
    color: '#9955ff',
    day: 9,
  },
]

const Challenges = (props) => {
  return (
    <Container title=''>
      <div className='landing-layout'>
        <section className='feature-section'>
          <div className='home-banner'>
            <h1 className='banner__title'>
              Learn React by building Applications
            </h1>
            <p
              style={{
                textAlign: 'left',
                color: '#193966',
                fontSize: '1.25rem',
                lineHeight: '1.855',
              }}
            >
              30 Days Of React is a step by step guide to learn React.js. It is
              structured based on days and the challenge goes form day 1 to 30.
              In the 30 Days Of React challenge , you will have a chance to
              practice with small exercises, solve different challenging
              problems and build more than 30 simple and complex applications.
              By the end of the challenge, your JavaScript and React and
              problem-solving skill will reach to a higher level. There are also
              <a
                href='https://github.com/Asabeneh/30-Days-Of-JavaScript'
                className='link'
                target='_blank'
                rel='noopener noreferrer'
              >
                30 Days Of JavaScript
              </a>
              and
              <a
                href='https://github.com/Asabeneh/30-Days-Of-Python'
                className='link'
                target='_blank'
                rel='noopener noreferrer'
              >
                30 Days Of Python
              </a>{' '}
              by the same author.
            </p>
            <a
              className='btn'
              href='https://github.com/Asabeneh/30-Days-Of-React'
              target='_blank'
              rel='noopener noreferrer'
            >
              {' '}
              Get Started
            </a>
          </div>
          <div className='hero'>
            <img
              className='img-fluid'
              src={thirtyDaysOfReact}
              alt='30 Days Of React '
              loading='lazy'
            />
          </div>
        </section>
      </div>
      <section
        className='day'
        style={{
          textAlign: 'center',
        }}
      >
        <h1>30 DAYS OF REACT PROJECTS</h1>
      </section>

      <section className='day'>
        <h1>Day 1: JavaScript Refresher</h1>
        <article>
          <p>Work on your JavaScript before you start React</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 2: Introduction to React</h1>
        <article>
          <p>Build some UI using JSX</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 3: JSX and Rendering JSX</h1>
        <article>
          <h4 className='web_techs__title'>Front End Technologies</h4>
          <article className='web-tech__images'>
            <img src={htmLogo} className ="img-fluid" alt='HTML Logo' loading='lazy' />
            <img src={cssLogo} className ="img-fluid" alt='CSS Logo' loading='lazy' />
            <img src={jsLogo} className ="img-fluid" alt='JavaScript Logo' loading='lazy' />
            <img src={ejsLogo} className ="img-fluid" alt='React Logo' loading='lazy' />
          </article>
        </article>
        <article>
          <img src={newLetter} style={styles} />
        </article>
        <article>
          <img src={userCard} style={styles} />
        </article>
      </section>

      <section className='day'>
        <h1>Day 4: Functional Components</h1>
        <article>
          <h4 className='web_techs__title'>Front End Technologies</h4>
          <article className='web-tech__images'>
            <img src={htmLogo} className ="img-fluid" alt='HTML Logo' loading='lazy' />
            <img src={cssLogo} className ="img-fluid" alt='CSS Logo' loading='lazy' />
            <img src={jsLogo} className ="img-fluid" alt='JavaScript Logo' loading='lazy' />
            <img src={ejsLogo} className ="img-fluid" alt='React Logo' loading='lazy' />
          </article>
        </article>
        <article>
          <img src={newLetter} style={styles} />
        </article>
        <article>
          <img src={hexadecimal_color_props} style={styles} />
        </article>
        <article>
          <img src={userCard} style={styles} />
        </article>
      </section>
      <section className='day'>
        <h1>Day 5: Props in Functional Components</h1>
        <article>
          <h4 className='web_techs__title'>Front End Technologies</h4>
          <article className='web-tech__images'>
            <img src={htmLogo} className="img-fluid" alt='HTML Logo' loading='lazy' />
            <img src={cssLogo} className="img-fluid" alt='CSS Logo' loading='lazy' />
            <img src={jsLogo} className="img-fluid" alt='JavaScript Logo' loading='lazy' />
            <img src={ejsLogo} className="img-fluid" alt='React Logo' loading='lazy' />
          </article>
        </article>
        <article>
          <img src={newLetter} style={styles} loading='lazy' />
        </article>
        <article>
          <img src={hexadecimal_color_props} style={styles} loading='lazy' />
        </article>
        <article>
          <img src={userCard} style={styles} loading='lazy' />
        </article>
      </section>

      <section className='day'>
        <h1>Day 6: Mapping List</h1>
        <article>
          <img src={numberGenerator} style={styles} loading='lazy' />
        </article>
        <article>
          <img src={hexadecimal} style={styles} />
        </article>
        <article>
          <img src={tenMostPopulatedCountries} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 7: Class Based Components</h1>
        <article>
          <p>
            Change the above functional components to class based components
          </p>
        </article>
      </section>

      <section className='day'>
        <h1>Day 8: State</h1>
        <article>
          <img src={changeBackground} style={styles} loading='lazy' />
        </article>

        <article>
          <img src={getCountryProject} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 9: Conditional Rendering</h1>
        <article>
          <p>It will be included</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 10:Folder Structure</h1>
        <article>
          <p>No specific project for this day</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 11: Event Handling</h1>
        <article>
          <img src={reactEvenOnMouse} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 12: React Form</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 13: Uncontrolled Input</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 14: React ComponentLife Cycles</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 15: Third Party packages</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 16: Higher Order Components</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 17: React Router</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 18: Fetching Data from Cat API</h1>
        <article>
          <img src={averageCatWeight} style={styles} />
        </article>
      </section>

      <section className='day'>
        <h1>Day 19: Fetching API:Part 1</h1>
        <a
          href='https://www.30daysofreact.com/day-19/cats'
          target='_blank'
          rel='noopener noreferrer'
        >
          DEMO
        </a>

        <article>
          <img src={cat19} style={styles} loading='lazy' />
        </article>
      </section>

      <section className='day'>
        <h1>Day 20: Fetching API:Part 1</h1>
        <a
          href=' https://www.30daysofreact.com/day-20/cats'
          target='_blank'
          rel='noopener noreferrer'
        >
          DEMO
        </a>
        <article>
          <img src={cat20} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 21: React Hooks</h1>
        <article>
          <p>Coming...</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 22: React Forum using Hooks</h1>
        <article>
          <p>Coming...</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 23: Fetching Data Using Hooks</h1>
        <a
          href=' https://www.30daysofreact.com/day-23/countries-data'
          target='_blank'
          rel='noopener noreferrer'
        >
          DEMO
        </a>

        <article>
          <img src={countriesData} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 24: Projects</h1>
        <article>
          <p>Coming.. </p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 25: Custom Hooks</h1>
        <article>
          <p>Coming ..</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 26: React Context</h1>
        <article>
          <p>Coming..</p>
        </article>
      </section>

      <section className='day'>
        <h1>Day 27: Generating Hexadecimal Color</h1>
        <a
          href='https://www.30daysofreact.com/day-27/hexadecimal-colors'
          target='_blank'
          rel='noopener noreferrer'
        >
          {' '}
          DEMO
        </a>
        <article>
          <img src={hexadecimalColorCopy} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 28: Twitter Clone(Old Twitter</h1>
        <a
          href='https://www.30daysofreact.com/day-28/twitter-clone'
          target='_blank'
          rel='noopener noreferrer'
        >
          DEMO
        </a>
        <article>
          <img src={twitterClone} style={styles} loading='lazy' />
        </article>
      </section>
      <section className='day'>
        <h1>Day 29: Explore different packages</h1>
        <article>
          <p>Try to explore different packages</p>
        </article>
      </section>
      <section className='day'>
        <h1>Day 30: Congratulations</h1>
        <article>
          <p>
            Congratulations for completing the challenge! Time to celebrate your
            success!
          </p>
        </article>
      </section>
      <section>
        <article>
          <Courses />
        </article>
      </section>
    </Container>
  )
}



export default Challenges
