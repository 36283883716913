import React from 'react'
import { format, formatISO9075 } from 'date-fns'
const Reply = ({ reply, likeReply, commentId }) => {
  return (
    <>
      <div className={`comment-container reply`}>
        <div className='comment-box'>
          <div className='comment-box-header'>
            <div className='comment-user-info'>
              <img src={reply.user.avatar} />
              <small>{reply.user.firstName}</small>
            </div>
            <div>
                <i class='far fa-regular fa-heart' onClick={() => likeReply(commentId, reply._id)}></i>{' '}
                {reply.likes.length > 0 && reply.likes.length}
              </div>
            <time>
              {format(new Date(reply.createdAt), 'MMM d, yyyy HH:mm')}
            </time>
          </div>
          <div>
            <p>{reply.content}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Reply
