import React, { useState, useEffect } from 'react'
import Posts from '../components/courses/post/Posts'
import Spinner from '../components/courses/spinner/Spinner'

const HomePage = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    fetchPosts()
  }, [])

  const fetchPosts = async () => {
    const url = '/api/posts'
    setLoading(true)
    const response = await fetch(url)
    const data = await response.json()
    setPosts(data)
    setLoading(false)
  }
  if (posts.length === 0 && loading) {
    return <Spinner />
  }

  return <>{posts.length > 0 && <Posts posts={posts} />}</>
}

export default HomePage
