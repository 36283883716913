import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import catImage from '../../assets/images/favicon.ico'
import Spinner from '../shared/Spinner'
import { NavLink } from 'react-router-dom'
import CatParadiseDay20 from '../day_20/CatParadiseDay20'
import Layout from '../Layout'
import './cat.scss'

const Footer = () => {
  return (
    <div className='cat-footer'>
      <div className='footer-wrapper'>
        <p> </p>
        <p>Copyright&copy;2020 30 Days Of React</p>
        <p>
          Join{' '}
          <a
            href='https://github.com/Asabeneh/30-Days-Of-React'
            target='_blank'
            rel='noopener noreferrer'
          >
            30 Days of React challenge
          </a>
        </p>
        <small>
          Designed and Built by{' '}
          <a
            href='https://www.linkedin.com/in/asabeneh/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Asabeneh Yetayeh
          </a>
        </small>
        <div className='arrow'>
          <a href='#root'>
            <i class='fas fa-arrow-alt-circle-up'></i>
          </a>
        </div>
      </div>
    </div>
  )
}

const Header = ({ data: { weight, age }, numberOfCats }) => {
  return (
    <div className='cat-header'>
      <div className='header-wrapper'>
        <div>
          <h1 className='challenge-name' id='title'>
            30 Days Of React
          </h1>
          <div>
            <NavLink to='/day-20/cats' className='nav-link'>
              {'Day 20 >>'}
            </NavLink>
          </div>
          <ul id='menu'>
            <li>
              <NavLink to='/' exact activeClassName='active'>
                HOME
              </NavLink>
            </li>
            <li>
              <NavLink to='/about' exact activeClassName='active'>
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/dummy-data-generator'
                exact
                activeClassName='active'
              >
                Dummy Data
              </NavLink>
            </li>
          </ul>
        </div>
        <div className='title'>
          <img src={catImage} alt='cat' className='cat-icon' />
          <h1 className='cats-header-title'>Cats Paradise</h1>
          <p>There are {numberOfCats} cat breeds </p>

          <small className='cat-summary'>
            On average a cat can weight about{' '}
            <strong className='average'>{weight}</strong> Kg and lives{' '}
            <strong className='average'>{age ? age : 0}</strong> years.
          </small>
        </div>
      </div>
    </div>
  )
}

const Cat = ({
  cat: {
    name,
    description,
    origin,
    temperament,
    life_span,
    url,
    weight: { metric },
  },
}) => {
  return (
    <div className='cat-card'>
      <div className='cat-card-image'>
        <img src={url} loading='lazy' alt={name} />
      </div>
      <div className='cat-card-body'>
        <div>
          <h1 className='cat-name'>{name}</h1>
          <p className='cat-origin'>
            <strong>{origin}</strong>
          </p>
        </div>
        <div className='cat-attributes'>
          <p>
            <span>Temperament:</span> {temperament}
          </p>
          <p>
            <span>Life Span:</span> {life_span} years
          </p>
          <p>
            <span>Weight:</span> {metric} Kg
          </p>
        </div>

        <div className='cat-desc'>
          <p>
            <span>Description</span>
          </p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  )
}
const Cats = ({ cats }) => {
  const catList = cats.map((cat) => <Cat cat={cat} />)
  if (cats.length > 0) {
    return <div className='cat-cards'>{catList}</div>
  }
  return (
    <div>
      <Spinner />
      <div className='cat-cards'></div>
    </div>
  )
}

class CatParadiseDay19 extends Component {
  state = {
    data: [],
  }
  constructor(props) {
    super(props)
    const url = window.location.origin
    if (!url.includes('localhost') && !url.includes('https')) {
      window.location = `https:${url.split(':')[1]}`
    }
  }

  componentDidMount() {
    this.fetchCountryData()
  }
  fetchCountryData = async () => {
    const url = 'https://api.thecatapi.com/v1/breeds'
    try {
      const response = await axios.get(url)
      const data = await response.data
      const modifyData = await Promise.all(
        data.map(async (cat) => {
          let url = await axios.get(
            `https://api.thecatapi.com/v1/images/search?breed_id=${cat.id}`
          )
          let image = await url.data[0].url

          cat.url = image
          return cat
        })
      )

      this.setState({
        data: modifyData,
      })
    } catch (error) {
      console.log(error)
    }
  }
  calculateAverage = () => {
    const weightAndAge = this.state.data.map((cat) => {
      const weight = cat.weight.metric
        .split(' - ')
        .reduce((acc, cur) => +acc + +cur)
      const age = cat.life_span.split(' - ').reduce((acc, cur) => +acc + +cur)
      return {
        weight: weight / 2,
        age: age / 2,
      }
    })

    let weight = 0
    let age = 0

    weightAndAge.forEach((item, i) => {
      weight += item.weight
      age += item.age
    })
    let average = {
      weight: (weight / weightAndAge.length).toFixed(2),
      age: (age / weightAndAge.length).toFixed(2),
    }

    return average
  }

  render() {
    return (
      <>
        <Header
          data={this.calculateAverage()}
          numberOfCats={this.state.data.length}
        />
        <Cats cats={this.state.data} />
        <Footer />
      </>
    )
  }
}

export default CatParadiseDay19
