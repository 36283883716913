import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import moment from 'moment'
import { UserContext } from '../../../context/UserContext'
import Spinner from '../spinner/Spinner'
import { format, formatISO9075 } from 'date-fns'

const UserDetail = () => {
  const { id } = useParams()
  const [profile, setProfile] = useState(null)
  const {user} = useContext(UserContext)
  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`)
      window.location.reload(true)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    axios
      .get(`/api/users/${id}`)
      .then((res) => {
        console.log(res)
        setProfile(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [id])

  if (!profile) {
return (
  <Container className='d-flex flex-column align-items-center pt-4 mb-3'>
    <Spinner />
  </Container>
)
  }

  const { firstName, lastName, email, createdAt, updatedAt, avatar, completed, position, linkedinUrl, githubUrl, twitterUrl, tel} = profile
  const progress = (completed.length / 30 * 100).toFixed(1)
  return (
    <Container className='d-flex flex-column align-items-center pt-4 mb-3'>
      <img
        src={avatar}
        alt={firstName}
        width={'10%'}
        className='rounded-circle avatar'
      />
      <h2 className='text-uppercase'>
        {firstName} {lastName}
      </h2>
  
      <small>{position}</small>

      <div className='d-flex justify-content-between my-4 w-25'>
       {
        linkedinUrl &&  <a href={linkedinUrl} rel='noreferrer noopener'>
        <i class="fab fa-linkedin"></i>
        </a>
       }
       {
       twitterUrl &&  <a href={twitterUrl} rel='noreferrer noopener'>
        <i class="fab fa-twitter-square"></i>
        </a>
       }
       {
        githubUrl &&  <a href={githubUrl} rel='noreferrer noopener'>
        <i class="fab fa-github-square"></i>
        </a>
       }

       
      </div>
      <div className='d-flex justify-content-between align-items-center w-50'>
       {
        tel &&  <small>
        <i class="fas fa-solid fa-phone"></i> {tel}
        </small>
       }
        <br />
       
        <small>
        <i class="fas fa-regular fa-envelope"></i> {email}
        </small>
        
      
      </div>

      <div>
        {user &&  <p>{user.bio}</p>}
        {/* <time>{moment(createdAt).fromNow()}</time><br /> */}
        <div>
          <small>
            Joined on
            <time> {moment(createdAt).calendar()}</time>
          </small>
          <br />
          <small>
            Last visited <time>{moment(updatedAt).calendar()}</time>
          </small>
        </div>
        <div>
        <hr />
      <div>
      <label for='file'>Your progress:</label> <br />
        <progress id='file' value={progress} max={100} style={{height:'20px'}}>
          {' '}
        </progress>
        <span>{progress} %</span>

      </div>
        </div>

      
        {user && (user.role[0] === 'Admin' || user.id === profile.id) && (
          <div className='d-grid gap-2 d-md-flex'>
            <Link
              to={`/users/edit/${id}`}
              className='btn btn-primary'
              role='button'
            >
              Edit
            </Link>
          { user && (user.role[0] === 'Admin') && <button
              className='btn btn-danger'
              onClick={() => deleteUser(id)}
            >
              Delete
            </button>  }
          </div>
        )}
      </div>
    </Container>
  ) 
}

export default UserDetail
